import * as React from 'react';
import './Careers.css';
import { NavLink } from 'react-router-dom';
import arrowRight from '../assets/img/arrow-grey-right.svg';
import { useTranslation } from 'react-i18next';

function JobPosition({ title, imageSrc }) {
    const { t } = useTranslation();

    return (
        <div className="job-position">
            <div className="job-title">{t(title)}</div>
            <img loading="lazy" src={imageSrc} alt={title} className="job-image" />
        </div>
    );
}

function CareersPage() {
    const { t } = useTranslation();

    const jobPositions = [
        {
            title: 'careers.positions.0.name',
            imageSrc: arrowRight,
        },
        {
            title: 'careers.positions.1.name',
            imageSrc: arrowRight,
        },
        {
            title: 'careers.positions.2.name',
            imageSrc: arrowRight,
        },
    ];

    return (
        <>
            <section className="careers-container">
                <section className="careers-hero-section">
                    <div className="careers-hero-content">
                        <div className="hero-info">
                            <div className="hero-text-container">
                                <h1 className="careers-hero-title">{t('careers.title')}</h1>
                                <p className="careers-hero-description">{t('careers.info')}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="job-pic">{t('careers.joinUs')}</div>
                <section className="job-section">
                    <div className="job-container">
                        <div className="job-list">
                            <div className="job-list-inner">
                                <div className="job-list-content">
                                    <header className="job-content">
                                        <h2 className="job-header">{t('careers.positions')}</h2>
                                        <div className="job-position-container">
                                            {jobPositions.map((position, index) => (
                                                <JobPosition key={index} title={position.title} imageSrc={position.imageSrc} />
                                            ))}
                                        </div>
                                    </header>
                                    <div className="contact-section">
                                        <p className="contact-text">{t('careers.notFound')}</p>
                                        <NavLink to="/contact" className="careers-nav-link">
                                            <div className="contact-btn">{t('careers.contact')}</div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="careers-image-section">
                            {/* <img loading="lazy" src={careersHero} alt="Company team working together" className="careers-hero-image" /> */}
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}

export default CareersPage;
